import * as React from "react";
import { FaWhatsapp  } from "react-icons/fa";
/*  https://react-icons.github.io/search */

function Whatsapp() {
  return (
    <div>
      <a
        href="https://api.whatsapp.com/send?phone=56961800817&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20*Netwon*%20"
        className="float"
        /* target="_blank" */
      >
        <i className="my-float">
          <FaWhatsapp  style={{margin: "5px 5px 16px 5px"}}/>
        </i>
      </a>
    </div>
  );
}

export default Whatsapp;
